import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Login from './Login';
import AddQuiz from './AddQuiz';
import ListQuiz from './ListQuiz';
import CorrectAnswer from './CorrectAnswer';
import Results from './Results';
import MyComponent from './MyComponent'; // Import MyComponent
import Register from './Register';
// import LogoutButton from './LogoutButton';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/add" element={<AddQuiz />} />
          <Route path="/list" element={<ListQuiz />} />
          <Route path="/correctanswers" element={<CorrectAnswer />} />
          <Route path="/results" element={<Results />} />
          <Route path="/mycomponent" element={<MyComponent />} /> {/* Add this Route */}
          {/* <Route path="/logout" element={<LogoutButton />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
