import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import './Login.css';

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    async function signIn() {
        let item = { email, password };
        console.warn(item);

        let result = await fetch("https://backend.blisstherapy.com.np/api/login", {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        });
        
        result = await result.json();
        // console.warn("result", result)
        localStorage.setItem("user-info", JSON.stringify(result));
        navigate("/add");
    }

    return (
        <>
        {/* <Header /> */}
    <div className="outer-container" style={{backgroundColor:'beige'}}>
        <div className="container-fluid d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginTop:'5px'}}>
            <div className="card mt-0 col-sm-6">
                <h1 className='offset-sm-4' style={{fontSize:'30px'}}>Login Page</h1>
                <br />
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="email"
                />
                <br />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    placeholder="password"
                />
                <br />
                <button onClick={signIn} className="btn btn-primary offset-sm-4" style={{ width: '160px', height: '45px', borderRadius: '40px' }}>Login</button>
            </div>
        </div>
    </div>
        </>
    );
}

export default Login;
