import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

const CorrectAnswer = () => {
  const [correctAnswers, setCorrectAnswers] = useState([]);

  useEffect(() => {
    const fetchCorrectAnswers = async () => {
      try {
        let response = await fetch("https://backend.blisstherapy.com.np/api/correct-answers");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        let result = await response.json();
        setCorrectAnswers(result);
      } catch (error) {
        console.error('Error fetching the correct answers:', error);
      }
    };

    fetchCorrectAnswers();
  }, []);

  return (
    <div>
      {/* <h1>Correct Answers</h1> */}
      <br />
      <Table className="table table-bordered table-dark">
        <thead className="thead-dark">
          <tr className="table-dark">
            <th scope="col">#</th>
            <th scope="col">Quiz</th>
            <th scope="col">Correct Answer</th>
          </tr>
        </thead>
        <tbody>
          {correctAnswers.map((quiz, index) => (
            <tr key={index} className="table-success">
              <th scope="row">{quiz.id}</th>
              <td>{quiz.quiz}</td>
              <td>{quiz.correctanswer}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default CorrectAnswer;
