import React, { useState } from 'react';

function AddQuiz() {
    const [quiz, setQuiz] = useState("");
    const [options, setOptions] = useState([
        { id: 1, value: '', isCorrect: false },
        { id: 2, value: '', isCorrect: false }
    ]);

    // Function to handle changes in quiz question
    const handleQuizChange = (e) => {
        setQuiz(e.target.value);
    };

    // Function to handle changes in options
    const handleOptionChange = (index, value) => {
        const updatedOptions = [...options];
        updatedOptions[index].value = value;
        setOptions(updatedOptions);
    };

    // Function to toggle correct answer for an option
    const toggleCorrectAnswer = (index) => {
        const updatedOptions = [...options];
        updatedOptions[index].isCorrect = !updatedOptions[index].isCorrect;
        setOptions(updatedOptions);
    };

    // Function to add a new option field
    const addOption = () => {
        const newId = options.length + 1;
        setOptions([...options, { id: newId, value: '', isCorrect: false }]);
    };

    // Function to handle submission of quiz
    const addQuiz = async () => {
        console.warn(quiz, options);
        const formData = new FormData();
        formData.append('quiz', quiz);
        options.forEach((option, index) => {
            formData.append(`option${index + 1}`, option.value);
            if (option.isCorrect) {
                formData.append('correctanswer', option.value);
            }
        });

        try {
            let result = await fetch("https://backend.blisstherapy.com.np/api/addquiz", {
                method: 'POST',
                body: formData,
            });
            alert("Data has been saved");
        } catch (error) {
            console.error('Error adding quiz:', error);
            alert("Failed to add quiz. Please try again.");
        }
    };

    return (
    <div className="outer-container" style={{backgroundColor:'beige'}}>
        <div className='card mt-5'>
            <h1 className='offset-sm-5'>Add Quiz</h1>
            <br />
            <div className="col-sm-6 offset-sm-3">
                <br />
                <input
                    type="text"
                    className="form-control"
                    value={quiz}
                    onChange={handleQuizChange}
                    placeholder="quiz"
                />
                <br />
                {options.map((option, index) => (
                    <div key={option.id} className="d-flex align-items-center mb-3">
                        <input
                            type="text"
                            className="form-control mr-2"
                            value={option.value}
                            onChange={(e) => handleOptionChange(index, e.target.value)}
                            placeholder={`option ${index + 1}`}
                            style={{ width: 'calc(100% - 40px)' }} // Adjust width here
                        />
                        <input
                            type="checkbox"
                            checked={option.isCorrect}
                            onChange={() => toggleCorrectAnswer(index)}
                            style={{ marginLeft: '10px' }}
                        />
                    </div>
                ))}
                <br />
                <div className="d-flex justify-content-between">
                    <button
                        onClick={addOption}
                        className='btn btn-danger'
                        style={{ borderRadius: '20px', width: '40px', height: '35px' }}
                    >
                        +
                    </button>
                    <button
                        onClick={addQuiz}
                        className='btn btn-primary'
                        style={{ borderRadius: '20px', width: '150px', height: '50px', marginLeft: '10px' }}
                    >
                        Add Quiz
                    </button>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AddQuiz;
