import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function Results() {
  const location = useLocation();
  const navigate = useNavigate();
  const { correct, incorrect } = location.state || { correct: 0, incorrect: 0 };

  return (
    <div className="container">
      <h1 className='offset-sm-5'>Quiz Results</h1>
      <br />
      <div className="col-sm-8 offset-sm-2">
        <h2>Results</h2>
        <p>Correct Answers: {correct}</p>
        <p>Incorrect Answers: {incorrect}</p>
        <Button variant="primary" onClick={() => navigate('/list')}>Back to Quiz List</Button>
      </div>
    </div>
  );
}

export default Results;
