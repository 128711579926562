import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming user is logged in initially for testing
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Placeholder for actual login state checking logic
    setIsLoggedIn(true); // Set to true for testing; implement actual check
  }, []);

  const handleLogout = async () => {
    // Show confirmation dialog before logging out
    if (window.confirm('Are you sure you want to logout?')) {
      try {
        const response = await axios.post('http://localhost:8000/api/logout');
        console.log('Logout response:', response.data);
        setIsLoggedIn(false);
        setShowAlert(true); // Show the alert when logout is successful
        // Optionally redirect to login page after a delay
        setTimeout(() => {
          window.location.href = '/login'; // Redirect to login page
        }, 2000); // Redirect after 2 seconds
      } catch (error) {
        console.error('Logout failed:', error);
        window.location.href = '/login'; // Redirect to login page even if logout fails
      }
    }
    // If user cancels, do nothing
  };

  const handleAlertClose = () => setShowAlert(false); // Function to close the alert

  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">Quiz</Navbar.Brand>
        <Nav className="me-auto">
          <Link to="/add" className="nav-link">Add Quiz</Link>
          <Link to="/list" className="nav-link">List Quiz</Link>
          <Link to="/login" className="nav-link">Login</Link>
          <Link to="/register" className="nav-link">Register</Link>
        </Nav>
        {isLoggedIn && (
          <Nav>
            <Nav.Link as="button" onClick={handleLogout} style={{ background: 'red', borderRadius: '15px', border: 'white', padding: '5px 15px', color: 'white', textDecoration: 'none' }}>
              Logout
            </Nav.Link>
          </Nav>
        )}
      </Navbar>
      <Alert show={showAlert} variant="danger" onClose={handleAlertClose} dismissible>
        Logout successful! Redirecting to login page...
      </Alert>
    </div>
  );
}

export default Header;
