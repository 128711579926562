import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './Register.css'; // Import CSS file for styling

const Register = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    mobile_number: '',
    address: '',
    district: '',
    gender: '',
    qualification: '',
    role: '', // New field for user role
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8000/api/register', formData);
      if (response && response.data) {
        console.log('Registration successful:', response.data);

        // Redirect based on user role
        if (formData.role === 'student') {
          navigate('/list'); // Navigate to list page for students
        } else if (formData.role === 'instructor') {
          navigate('/add-quiz'); // Navigate to add quiz page for instructors
        }

        // Optionally, reset form fields upon successful registration
        setFormData({
          name: '',
          email: '',
          password: '',
          mobile_number: '',
          address: '',
          district: '',
          gender: '',
          qualification: '',
          role: '',
        });
      } else {
        console.error('Empty response or response data');
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with error:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error('Error while setting up request:', error.message);
      }
    }
  };

  return (
    <div className="outer-container" style={{backgroundColor:'beige'}}>
      <div className="card">
        <h2 className="text-center underline">Register</h2>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" style={{ marginRight: '30px' }}>Name</label>
            <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} placeholder="Name" style={{ width: '400px' }} />
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="email" style={{ marginRight: '30px' }}>Email</label>
            <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} placeholder="Email" style={{ width: '400px' }} />
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="password" style={{ marginRight: '30px' }}>Password</label>
            <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} placeholder="Password" style={{ width: '400px' }}/>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="mobile_number" style={{ marginRight: '30px' }}>Mobile Number</label>
            <input type="text" name="mobile_number" id="mobile_number" value={formData.mobile_number} onChange={handleChange} placeholder="Mobile Number" style={{ width: '400px' }} />
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="address" style={{ marginRight: '30px' }}>Address</label>
            <input type="text" name="address" id="address" value={formData.address} onChange={handleChange} placeholder="Address" style={{ width: '400px' }}/>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="district" style={{ marginRight: '30px' }}>District</label>
            <select name="district" id="district" value={formData.district} onChange={handleChange} style={{ width: '400px' }}>
              <option value="">Select District</option>
              <option value="Kathmandu">Kathmandu</option>
              <option value="Pokhara">Pokhara</option>
              <option value="Bhaktapur">Bhaktapur</option>
              <option value="Chitwan">Chitwan</option>
              <option value="Dhading">Dhading</option>
              <option value="Dolakha">Dolakha</option>
              <option value="Kavrepalanchok">Kavrepalanchok</option>
              <option value="Lalitpur">Lalitpur</option>
              <option value="Makwanpur">Makwanpur</option>
              <option value="Nuwakot">Nuwakot</option>
              <option value="Ramechhap">Ramechhap</option>
              <option value="Rasuwa">Rasuwa</option>
              <option value="Sindhuli">Sindhuli</option>
              <option value="Sindupalchok">Sindupalchok</option>
              <option value="Gorkha">Gorkha</option>
            </select>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="gender" style={{ marginRight: '30px' }}>Gender</label>
            <select name="gender" id="gender" value={formData.gender} onChange={handleChange} style={{ width: '400px' }}>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="qualification" style={{ marginRight: '30px' }}>Qualification</label>
            <select name="qualification" id="qualification" value={formData.qualification} onChange={handleChange} style={{ width: '400px' }}>
              <option value="">Select Qualification</option>
              <option value="SLC">SLC/SEE</option>
              <option value="Intermediate">+2/A-Levels</option>
              <option value="Undergraduate">Undergraduate</option>
              <option value="Post Graduate">Post Graduate</option>
            </select>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="role" style={{ marginRight: '30px' }}>Role</label>
            <select name="role" id="role" value={formData.role} onChange={handleChange} style={{ width: '400px' }}>
              <option value="">Select Role</option>
              <option value="student">Student</option>
              <option value="instructor">Instructor</option>
            </select>
          </div>
          <br />
          <button className='btn btn-primary offset-sm-4' type="submit" style={{ marginTop: '38px', width: '140px', height: '45px', borderRadius: '20px' }}>Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;
