import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ListQuiz() {
  const [quizzes, setQuizzes] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        let response = await fetch("https://backend.blisstherapy.com.np/api/list");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        let result = await response.json();
        setQuizzes(result);
      } catch (error) {
        console.error('Error fetching the quiz list:', error);
      }
    };

    fetchQuizzes();
  }, []);

  const handleOptionSelect = (quizId, selectedOption) => {
    setSelectedAnswers(prevState => ({
      ...prevState,
      [quizId]: selectedOption
    }));
  };

  const handleViewCorrectAnswers = () => {
    navigate('/correctanswers');
  };

  const handleSubmitAnswers = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    quizzes.forEach((quiz) => {
      if (selectedAnswers[quiz.id] === quiz.correctanswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    // Redirect to the results page with the results data
    navigate('/results', { state: { correct: correctCount, incorrect: incorrectCount } });
  };

  return (
    <div>
      <h1 className='offset-sm-5'>List Quiz</h1>
      <br />
      <div className="col-sm-8 offset-sm-2">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Quiz</th>
              <th>Option 1</th>
              <th>Option 2</th>
              <th>Option 3</th>
              <th>Option 4</th>
            </tr>
          </thead>
          <tbody>
            {quizzes.map((quiz, index) => (
              <tr key={index}>
                <td>{quiz.id}</td>
                <td>{quiz.quiz}</td>
                <td>
                  <Button
                    variant={selectedAnswers[quiz.id] === quiz.option1 ? "primary" : "success"}
                    onClick={() => handleOptionSelect(quiz.id, quiz.option1)}
                  >
                    {quiz.option1}
                  </Button>
                </td>
                <td>
                  <Button
                    variant={selectedAnswers[quiz.id] === quiz.option2 ? "primary" : "danger"}
                    onClick={() => handleOptionSelect(quiz.id, quiz.option2)}
                  >
                    {quiz.option2}
                  </Button>
                </td>
                <td>
                  <Button
                    variant={selectedAnswers[quiz.id] === quiz.option3 ? "primary" : "info"}
                    onClick={() => handleOptionSelect(quiz.id, quiz.option3)}
                  >
                    {quiz.option3}
                  </Button>
                </td>
                <td>
                  <Button
                    variant={selectedAnswers[quiz.id] === quiz.option4 ? "primary" : "warning"}
                    onClick={() => handleOptionSelect(quiz.id, quiz.option4)}
                  >
                    {quiz.option4}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button variant="primary" onClick={handleSubmitAnswers}>Submit Answers</Button>
        <Button variant="secondary" onClick={handleViewCorrectAnswers}>View Correct Answers</Button>
      </div>
    </div>
  );
}

export default ListQuiz;
